import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Slider from 'react-slick';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Navigation, Pagination } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import Layout from '../components/Layout';

import LarsRasmussen from '../assets/images/learn/speakers/lars.png';
import DavidKerr from '../assets/images/learn/speakers/david.png';
import JasonBarnwell from '../assets/images/learn/speakers/jason.png';
import HelgaButcher from '../assets/images/learn/speakers/helga.png';
import StephanieHamon from '../assets/images/learn/speakers/stephanie.png';
import PaulHogg from '../assets/images/learn/speakers/paul.png';
import CatherineGoodman from '../assets/images/learn/speakers/catherine.png';
import RalphBaxter from '../assets/images/learn/speakers/ralph.png';
import GaryTully from '../assets/images/learn/speakers/gary.png';
import ChrisGrant from '../assets/images/learn/speakers/chris.png';

import ArrowDown from '../assets/images/learn/arrow-down.svg';
import ComingSoon from '../assets/images/learn/coming-soon.png';
import PurpleQuarterCircle from '../assets/images/learn/purple-quarter-circle.png';
import PinkCircle from '../assets/images/learn/pink-circle.png';
import Play from '../assets/svg/play-button.svg';
import playSmall from '../assets/images/icons/play.svg';
import ArrowLeftIcon from '../assets/svg/arrow-left.svg';
import ArrowRightIcon from '../assets/svg/arrow-right.svg';

const speakers = [
  {
    name: 'Lars Rasmussen',
    description: 'Lars is a world renowned computer scientist, having co-founded Google Maps and having previously served as the director of engineering for Facebook. He holds a PhD in computer science from the University of California, Berkeley.',
    image: LarsRasmussen,
  },
  {
    name: 'David Kerr',
    description: 'David until recently was CEO of Bird & Bird for 26 years, and during that time built it up to be the leading global law firm advising companies being changed by tech. He has a passion for harnessing legaltech to improve the delivery of legal services, and advises or is involved with several companies and clients in the area.',
    image: DavidKerr,
  },
  {
    name: 'Jason Barnwell',
    description: 'Jason is an attorney in Microsoft’s Corporate, External and Legal Affairs department. He leads the Microsoft’s Modern Legal team, with previous roles leading Legal Business, Operations and Strategy team, leading the Open Source Software practice group, and counseling the Cloud and AI platform business.',
    image: JasonBarnwell,
  },
  {
    name: 'Paul Hogg',
    description: 'Paul is a Relationship Manager at Barclays. He works within a dynamic team who are tasked with driving change across the legal function and delivering at pace. He plays a pivotal role in panel firm relationships.',
    image: PaulHogg,
  },
  {
    name: 'Stéphanie Hamon',
    description: 'Stéphanie ensures that the legal function of major corporations operates as an efficient business partner. She seeks to transform legal service delivery one step at a time. Expert in setting and delivering commercial management strategies for in-house legal departments as well as broader legal operations strategies and implementation.',
    image: StephanieHamon,
  },
  {
    name: 'Ralph Baxter',
    description: 'Ralphs served as Chairman & CEO of Orrick for nearly a quarter of a century. He was pivotal to transforming Orrick from a domestic firm to become one of the world’s most prominent global law firms, with more than 1,100 lawyers in 25 offices across the United States, Europe and Asia.',
    image: RalphBaxter,
  },
  {
    name: 'Chris Grant',
    description: 'Chris has broad experience working across the legal industry with time spent in both private practice and in-house. The majority of his career has been focused on legal operations, bringing efficiencies to traditional ways of working.',
    image: ChrisGrant,
  },
  {
    name: 'Helga Butcher',
    description: 'Helga is the Interim Head of Legal Project Management and Process Improvement (EMEA) at Ashurst. She has a wealth of experience in legal project management (LPM) and has recently collaborated with industry pioneers in the creation of a LPM competency framework to help professionalise the role of an LPM.',
    image: HelgaButcher,
  },
  {
    name: 'Catherine Goodman',
    description: 'Cathy leads the Practice Innovation & Knowledge Counsel at Paul Hastings, and is part of the global team named 2020 ‘Innovators of the Year’ by the International Legal Technology Association for outstanding achievements in maximising the value of innovative technology in support of legal professionals.',
    image: CatherineGoodman,
  },
  {
    name: 'Gary Tully',
    description: 'Gary is the Head of Legal Operations at Gilead. He has a demonstrated history of working in the biotechnology and high tech industries. In late 2021, Gary’s team was awarded law.com’s ‘Best Legal Ops Team’ having navigated the development of Covid-19 treatment amidst the pandemic.',
    image: GaryTully,
  },
];

SwiperCore.use([Controller, Navigation, Pagination]);

const Learn = ({ path, data }) => {
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const episodes = nodes.map((n) => ({
    episode: n.frontmatter.episode,
    slug: n.frontmatter.slug,
    title: n.frontmatter.title,
    subheading: n.frontmatter.subheading,
    subtitle: n.frontmatter.subtitle,
    duration: n.frontmatter.duration,
    thumbnail: n.frontmatter.thumbnail,
    speaker: n.frontmatter.speaker,
  })).sort((a, b) => ((a.episode > b.episode) ? 1 : -1));

  const comingSoonEpisodes = [];

  for (let i = 11; i <= 11; i += 1) {
    comingSoonEpisodes.push(i);
  }

  const [showMore, setShowMore] = useState(false);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const batchMainEpisodes = [
    {
      title: 'Legal Operations in Practice',
      caption: 'Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, the burgeoning role of legal project managers, and why legal operations is here to stay.',
      episode: episodes[6],
    },
    {
      title: 'Learn from the shoulders of Giants',
      caption: 'We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields.',
      episode: episodes[0],
    },
  ];

  return (
    <Layout
      title="Learn - Fibonacci"
      description="Learn from the shoulders of Giants. We reveal the secrets from the battlefield in a series of masterclasses conducted by some of the most influential thinkers in the technology and legal services fields."
      image="/metaimages/masterclass.png"
      pageName="learn"
      path={path}
      requestDemo="explore"
    >
      <div className="banner">
        <div className="chip">LEARN</div>
        <div className="swiper">
          <Swiper
            loop
            pagination={{ clickable: true, el: '#banner-swiper-pagination' }}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            observer
            observeParents
          >
            {
              batchMainEpisodes.map((e) => (
                <SwiperSlide key={e.episode.episode}>
                  <h1>
                    {e.title}
                  </h1>
                  <p>
                    {e.caption}
                  </p>
                </SwiperSlide>
              ))
            }
          </Swiper>
          <div id="banner-swiper-pagination" />
          <Swiper
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
            navigation={
              {
                nextEl: '#banner-swiper-next',
                prevEl: '#banner-swiper-prev',
              }
            }
            loop
            observer
            observeParents

          >
            {
              batchMainEpisodes.map((e) => (
                <SwiperSlide key={e.episode.episode}>
                  <div className="learn-banner">
                    <div
                      className="watch-episode-container"
                      style={{ background: `url(${e.episode.thumbnail}) center / cover no-repeat` }}
                    >
                      <Link
                        className="episode"
                        to={e.episode.slug}
                      >
                        <button
                          className="watch-button"
                        >
                          <div className="play-icon">
                            <img src={playSmall} alt="" />
                          </div>
                          <div>Start watching</div>
                        </button>
                        <div className="details">
                          <div className="number">{`Episode ${e.episode.episode}`}</div>
                          <div className="title">{`${e.episode.title} ${e.episode.subheading ? `- ${e.episode.subheading}` : ''}`}</div>
                        </div>
                      </Link>
                    </div>
                  </div>


                </SwiperSlide>
              ))
            }
          </Swiper>
          <div className="button-container">
            <button id="banner-swiper-prev">
              <ArrowLeftIcon fill="black" />
            </button>
            <button id="banner-swiper-next">
              <ArrowRightIcon fill="black" />
            </button>
          </div>
        </div>
      </div>
      <div className="episode-list-section">
        <div className="episode-list fit-width">
          <div className="header">Episodes</div>
          <div className="list">
            {
              episodes.slice(0, 10).map((e) => (
                <Link to={e.slug} key={e.episode} className="episode">
                  <div className="preview">
                    <img src={e.thumbnail} alt="preview" />
                    <button
                      className="play-button center -transparent-white"
                    >
                      <Play opacity="0.6" height="46" width="46" alt="play icon" />
                    </button>
                  </div>
                  <div className="details">
                    <div className="count">
                      {`Episode ${e.episode}`}
                    </div>
                    <div className="title">{`${e.title} ${e.subheading ? `- ${e.subheading}` : ''}`}</div>
                    <div className="speaker-credentials">
                      {
                        e.speaker
                          ? `by ${e.speaker}`
                          : ''
                      }
                    </div>
                    <div className="content">{e.subtitle}</div>
                    <div className="duration">{e.duration}</div>
                  </div>
                </Link>
              ))
            }
            {
              showMore
                && (
                  <>
                    {
                      comingSoonEpisodes.map((episode) => (
                        <div className="episode" key={episode}>
                          <div className="preview">
                            <img src={ComingSoon} alt="coming soon" />
                          </div>
                          <div className="details">
                            <div className="count">
                              {`Episode ${episode}`}
                            </div>
                            <div className="title">Coming Soon</div>
                          </div>
                        </div>
                      ))
                    }
                  </>
                )
            }
          </div>
          <div className="show">
            <button className="button" onClick={() => setShowMore(!showMore)}>
              <span className="label mr-3 tracking-[-0.02em]">
                Show
                {showMore ? ' less' : ' more'}
              </span>
              <img
                className={showMore ? 'invert' : 'arrow'}
                width="16"
                height="10"
                alt="arrow down"
                src={ArrowDown}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="learn-more-section">
        <div className="learn-more fit-width">
          <img
            alt="Purple circle"
            className="shape-1"
            src={PurpleQuarterCircle}
          />
          <img
            className="shape-2"
            alt="Pink circle"
            src={PinkCircle}
          />
          <div className="learn-more-content">
            <div className="text">Fibonacci is powering the world&apos;s best legal operations departments.</div>
            <Link className="button" to="/features/">
              <span>Learn more</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="speakers-list-section">
        <div className="speaker-list fit-width">
          <div className="header">Speakers</div>
          <div className="list">
            {speakers.map((speaker) => (
              <div className="speaker" key={speaker.name}>
                <img className="image" src={speaker.image} alt="speaker" />
                <div className="details">
                  <div className="name">{speaker.name}</div>
                  <div className="brief">{speaker.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="speakers-list-section -mobile">
        <div className="speaker-list -mobile fit-width">
          <div className="header">Speakers</div>
        </div>
      </div>
      <div className="speaker-list -mobile" style={{ width: '94vw', margin: '0 auto' }}>
        <Slider centerMode arrows={false}>
          {speakers.map((speaker) => (
            <div key={speaker.name} className="speaker-container">
              <div className="speaker">
                <img className="image" src={speaker.image} alt="speaker" />
                <div className="details">
                  <div className="name">{speaker.name}</div>
                  <div className="brief">{speaker.description}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          duration
          episode
          title
          subheading
          subtitle
          slug
          thumbnail
          speaker
        }
      }
    }
  }
`;

export default Learn;
