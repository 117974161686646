import React from 'react';

import shape1 from '../assets/images/gold-ball.png';

const Explore = ({ showMenu, setShowMenu, showModal, setShowModal }) => {

  return (
    <div className="explore-section">
      <img className="gold-ball" src={shape1} alt="" />
      <div className="content fit-width">
        <div className="title">Explore how Fibonacci can transform your legal practice.</div>
        <button onClick={() => setShowModal(!showModal)} className="base-button -purple lg-show">Request a demo</button>
        <button onClick={() => setShowMenu(!showMenu)} className="base-button -purple -medium sm-show">Request a demo</button>
      </div>
    </div>
  )
}
export default Explore;
