import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from "@reach/router"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import '../assets/stylesheets/application.scss';

import Header from './Header';
import Footer from './Footer';
import GetStarted from './GetStarted';
import Explore from '../components/Explore';
import CookieConsent from './CookieConsent';
import { GOOGLE_RECAPTCHA_KEY }  from '../utils/variables';

const Layout = ({ children, pageName, title = "Fibonacci", image, description, metatitle, requestDemo }) => {
  let className = '';

  if (pageName) {
    className = `${className} page-${pageName}`;
  }
  const { pathname } = useLocation();
  const path = pathname;

  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.fibonacci.legal${path}`} />
        <meta property="og:title" content={metatitle || title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`https://www.fibonacci.legal${image}`} />
      </Helmet>
      <div className="content-wrapper">
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
          <Header
            path={path}
            showMenu={showMenu}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <main>
            <div className="content">
              {children}
            </div>
          </main>
          {
            requestDemo === 'explore' ? 
                <Explore
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                showModal={showModal}
                setShowModal={setShowModal}
              />
              :
              <GetStarted
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                showModal={showModal}
                setShowModal={setShowModal}
              />
          }
          <Footer />
          <CookieConsent />
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
