import React, { useEffect, useState } from 'react';

import EarlyAccessForm from './EarlyAccessForm';

import shape3 from '../assets/images/shape-3.svg';
import shape4 from '../assets/images/shape-4.svg';
import shape6 from '../assets/images/shape-6.svg';
import shape7 from '../assets/images/shape-7.svg';

const GetStarted = ({ showMenu, setShowMenu, showModal, setShowModal }) => {

  return (
    <div className="get-started">
      <div className="content">
        <div className="title">Ready to get started?</div>
        <div className="subtitle">Don’t rely on your imagination.</div>
        <div className="subtitle">See Fibonacci in action.</div>
      </div>
      <button onClick={() => setShowModal(!showModal)} style={{ margin: '0 auto' }} className="base-button -primary -center lg-show">Request a demo</button>
      <button onClick={() => setShowMenu(!showMenu)} style={{ margin: '0 auto' }} className="base-button -primary -center sm-show">Request a demo</button>
      <img className="topright" src={shape3} alt="" />
      <img className="bottomleft" src={shape4} alt="" />

      <img className="mobileleft" src={shape6} alt="" />
      <img className="mobileright" src={shape7} alt="" />
    </div>
  )
}
export default GetStarted;
